import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Projets Récents</strong>
        </h1>
        <p style={{ color: "white" }}>
          Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="AtlasGming"
              description="Chez Atlas Gaming, nous sommes des pionniers dans l'analyse des jeux et des sports électroniques. Notre mission est de fournir des insights stratégiques pour naviguer dans le paysage dynamique du divertissement interactif.

Depuis notre création, nous nous engageons à fournir des données précises et des analyses approfondies pour aider nos clients à prendre des décisions éclairées et à capitaliser sur les opportunités du marché numérique."
              ghLink="https://github.com/Astr0TV/AtlasGaming_front-end"
              demoLink="http://atlasgaming.fr/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="ALLIANZ"
              description="ALLIANZ – de Octobre 2022 à Mars 2023 (Forfait) :

Migration des workflows RSR/SFCR pour la génération des rapports annuels de comptabilité vie, de SharePoint Designer vers Power Automate. Développement sous SharePoint/SPFX incluant la migration des formulaires, tels que les formulaires de tâche et la liste des tâches. Réalisation de tests unitaires et rédaction de la documentation technique associée."
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="ESICcertif"
              description="Développement de l'application EsiCertif 'ESIC-EDOF' :

Rédaction du cahier des charges détaillant les spécifications fonctionnelles et techniques de l'application, suivie de la création de maquettes interactives avec Adobe XD pour visualiser l'interface utilisateur. Modélisation de l'architecture de l'application à l'aide de diagrammes UML pour structurer les composants et les interactions du système. Développement frontend avec Angular pour créer une interface utilisateur dynamique et réactive, et backend avec Spring pour gérer la logique métier et les opérations serveur. Déploiement de l'application sur un serveur pour assurer son accessibilité et son fonctionnement optimal. Les technologies utilisées incluent MySQL pour le stockage et la gestion des données, des API REST pour la communication entre le frontend et le backend, Bootstrap pour le design et la mise en forme des interfaces, et l'intégration avec Google Sheets pour enrichir les fonctionnalités de l'application."
              ghLink="https://github.com/Astr0TV/esicertif_front"
              demoLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
