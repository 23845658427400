import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import './AboutCard.css'

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="quote-text">
            Bonjour à tous, je suis <span className="purple">Lamsaouri Ismail</span>
            <br />
            Je suis développeur Web junior formé à La ESIC et vis actuellement sur PARIS.
            <br />
            J'ai obtenu un <span className="purple">Master 2 EXPERT EN SYSTEMES D'INFORMATION ET SECURITE</span> à l'ESIC - Ecole d'informatique et de management commercial à Malakoff, France, de septembre 2022 à septembre 2023.
            <br />
            J'ai également une <span className="purple">Licence 3ème CONCEPTEUR DEVELOPPEUR D'APPLICATIONS</span> de l'ESIC - Ecole d'informatique et de management commercial à Malakoff, France, de septembre 2020 à septembre 2021.
            <br />
            Passionné par les technologies, la physique et le monde qui nous entoure, je suis <span className="purple">ouvert à tous types de secteurs</span> et pourrais faire profiter de mon expérience du milieu industriel et de mes qualités d'organisation dans la conduite de projets.
            <br />
            En dehors du codage, voici quelques autres activités que j'adore faire !
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Jouer aux jeux
            </li>
            <li className="about-activity">
              <ImPointRight /> Écrire des blogs technologiques
            </li>
            <li className="about-activity">
              <ImPointRight /> Voyager
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
